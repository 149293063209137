.kpi {
    width: 19%;
    height: 100%;
    border: 1px solid #7ACFEC;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .icon img {
    width: 100%;
    height: 100%;
  }
  
  .value {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 14px;
    color: #666;
  }