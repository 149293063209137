.main {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ciano {
    color: var(--ciano);
}

.laranja {
    color: var(--laranja);
}

.cinza {
    color: var(--cinza);
}

/* Estilos para desktop */
#principalTopo {
    background-image: url(../../utils/assets/fundoTopo.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 96vw;
    height: 75vh;
    margin: auto;
    margin-top: 2.9vh;
    border-radius: 15px;
    padding: 0;
    display: flex;
    box-shadow: 2px 5px 10px 0px #8b8b8b;
}

#principalTopo #esquerdaTopo {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: space-around;
    height: 80%;
    margin: auto;
}

#principalTopo #esquerdaTopo h1 {
    font-size: 3.4rem;
    line-height: 3.7rem;
    color: var(--primaryTextColor);
}

#principalTopo #esquerdaTopo p {
    color: var(--primaryTextColor);
    font-size: 1.2rem;
    width: 80%;
}

#principalTopo #esquerdaTopo div {
    width: 40%;
    height: 20%;
}

#principalTopo #esquerdaTopo div button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    background-color: #1F1F1F;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    box-shadow: #606D80 4px 4px 0px;
    cursor: pointer;
}

#principalTopo #esquerdaTopo div button:hover {
    background-color: #383838;
    transition: 0.5s;
    box-shadow: #47edff 4px 4px 0px;
}

.btnLink {
    text-decoration: none;
    color: #f1f1f1;
}

#principalTopo #direitaTopo {
    width: 46%;
    height: 100%;
}

#principalTopo #direitaTopo div {
    margin-top: 5%;
    width: 100%;
    height: 100%;
}

#principalTopo #direitaTopo div img {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 0 10px #33333389);
}

#comoFunciona{
    /* background-color: #beb1a8; */
    width: 90vw;
    /* min-height: 60vw; */
    height: auto;
    margin: auto;
    margin-top: 12vh;
}
#comoFunciona .divTitulo{
    margin: auto;
    width: 55vw;
    text-align: center;
    /* background-color: #00BABF; */
    margin-bottom: 8vh;
}

#comoFunciona .divTitulo .tituloCentro{
    font-size: 2.6rem;
    color: var(--primaryTextColor);
    margin: 10vh 0;
}
#comoFunciona #componentesComoFunc{
    display: flex;
    justify-content: space-between;
    /* background-color: rgb(128, 158, 128); */
    width: 100%;
    margin-top: 5vh;
}

#areacardServicoCarroselHome .divTitulo{
    /* margin: auto; */
    width: auto;
    /* background-color: #00BABF; */
    margin-bottom: 8vh;
    /* background-color: blue; */
}
#areacardServicoCarroselHome .divTitulo .tituloServicos{
    text-align: initial;
    font-size: 2.6rem;
    color: var(--primaryTextColor);
}

#cardServicoCarroselHome{
    /* background-color: #beb1a8; */
    width: 90vw;
    /* min-height: 60vw; */
    height: auto;
    margin: auto;
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15vh;
}

#cardServicoCarroselHome .cardServico{
    width: 24vw;
    height: 70vh;
    /* background-color: #1F1F1F; */
    border: 1px solid #b5b5b5;
    border-radius: 0.8rem;
    padding: 0 1vw;

}
#cardServicoCarroselHome .cardServico .cardServicoImg{
    width: 100%;
    height: 60%;
    /* background-color: #00BABF; */
    border-radius: 0.8rem 0.8rem 0 0;
}
#cardServicoCarroselHome .cardServico .cardServicoTitulo, #cardServicoCarroselHome .cardServico .cardServicoOrcamento{
    color: var(--primaryTextColor);
}
#cardServicoCarroselHome .cardServico .cardServicoDescricao, #cardServicoCarroselHome .cardServico .cardServicoAvaliacao{
    margin: 0.6rem 0;
}
#sobreNos{
    /* background-color: #beb1a8; */
    width: 90vw;
    /* min-height: 60vw; */
    height: auto;
    margin: auto;
    margin-top: 5vh;
   
    margin-bottom: 15vh;
}
#sobreNos .divTitulo, #sobreNos .divTitulo .tituloCentro{
    margin-bottom: 3vh;
}
#sobreNosCorpo{
    display: flex;
    justify-content: space-between;
}
#sobreNosCorpo #sobreNosEsquerda{
    width: 45%;
    height: 80vh;
    /* background-color: #606D80; */
}
#sobreNosCorpo #sobreNosEsquerda h2{
    margin-bottom: 5vh;
}
#sobreNosCorpo #sobreNosEsquerda p{
    line-height: 2.2rem;
}
#sobreNosCorpo #sobreNosEsquerda div{
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
}
#sobreNosCorpo #sobreNosEsquerda div h3{
    font-size: 2.5rem;
    line-height: 2.5rem;
}
#sobreNosCorpo #sobreNosEsquerda div h3 p{
    font-weight: 500;
    font-size: 1rem;
}
#sobreNosCorpo #sobreNosDireita{
    width: 45%;
    height: 80vh;
    /* background-color: lightblue; */
    display: flex;
    justify-content: flex-end ;
    margin-top: 2vh;
}
#sobreNosCorpo #sobreNosDireita img{
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 0 6px #61616189);
    object-fit: cover;
    border-radius: 1rem;
}
#proposito{
    width: 90vw;
    height: auto;
    margin: 15vh auto;
}
#proposito .divTitulo{
    height: fit-content;
    line-height: 1rem;
    margin: 20vh auto;
}
#proposito #propositoCards{
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
}

@media (max-width: 768px) {
    #principalTopo {
        flex-direction: column;
        
        height: 70vh;
        padding: 10px;
    }
    #principalTopo #esquerdaTopo{
        height: 100% !important;
    }

    #principalTopo #esquerdaTopo, 
    #principalTopo #direitaTopo {
        width: 100%;
        height: auto;
        align-items: center;
        text-align: center;
    }

    #principalTopo #esquerdaTopo h1 {
        font-size: 2.5rem;
        line-height: 3rem;
    }

    #principalTopo #esquerdaTopo p {
        font-size: 1rem;
        width: 90%;
    }

    #principalTopo #esquerdaTopo div {
        width: 60%;
        height: auto;
        margin: 20px 0;
    }
    #principalTopo #direitaTopo div {
        display: none;
    }
    #principalTopo #direitaTopo div img {
        width: 80%;
        height: auto;
    }

    #comoFunciona .divTitulo {
        width: 80vw;
    }

    #cardServicoCarroselHome, #areaCardServicoCarroselHome .divTitulo {
        display: none;
    }

    #sobreNos{
        margin-top: 25vh;
    }
    #sobreNos #sobreNosCorpo #sobreNosDireita{
        display: none;
    }
    #sobreNosCorpo {
        flex-direction: column;
        align-items: center;
    }

    #sobreNosCorpo #sobreNosEsquerda, 
    #sobreNosCorpo #sobreNosDireita {
        width: 90%;
        height: auto;
    }

    #proposito #propositoCards {
        flex-direction: column;
        align-items: center;
    }

    #proposito #propositoCards div {
        width: 80vw;
        margin-bottom: 20px;
    }
}