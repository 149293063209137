main{
    display: flex;
}

.container{
    position: relative;
    width: 30%;
    height: 100vh;
    /* background-color: red; */
}
.container2{
    width: 70%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}        
form{
    display: flex;
    align-items: center;
    flex-direction: column;
    
    height: 500px;
    width: 50%;

    border-radius: 5px;
    padding: 0;
    /* background-color: red; */
}
.input-group{
    display: flex;
    align-items: center;
    flex-direction: column;

    min-width: 100%;
    height: 100%;
    /* background-color: red; */
}

.button-group{
    width: 100%;
}
.button-group button{
    width: 100%;
    padding: 5%;
    border-radius: 4px;
    border: none;

    background-color: #606D80;
    text-align: start;
    color: #FFFFFF;
}
.button-group button:hover{
    background-color: #4D5A6C;
    cursor: pointer;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    text-align: center;
    margin-bottom: 5%;
}
.title h2{
    color: #212121;
    margin-bottom: 1rem;
}
.title h3{
    font-size: smaller;
    color: #424242;
}
.link-cadastro{
    height: 50px;
}
.link-cadastro a{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #606D80;
    text-decoration: none;
}
.link-cadastro span{
    color: #212121;
}