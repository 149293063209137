.top-services {
    border-radius: 8px;
    border: 1px solid #7ACFEC;
    background-color: #fff;
    width: 80%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .top-services h3 {
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .table-header,
  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 70%;
  }
  
  .table-header {
    border-bottom: 1px solid #ccc;
  }
  
  .rank {
    color: #1E90FF;
    font-weight: bold;
    margin-right: 10px;
    padding-left: 5px;
  }
  