.inputs {
    position: relative;
    width: 100%;
    height: 60px;
    margin-bottom: 3%;
  }
  
  .inputs input {
    width: 98.5%;
    height: 100%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #757575;
    color: #757575;
    padding-left: 5px;
  }
    
  .inputs input:focus{ 
    border: 1px solid var(--ciano);

  }
  
  .inputs select {
    width: 100%;
    height: 32px;
    outline: none;
    margin-top: 3%;
    border-radius: 3px;
    border: 1px solid #757575;
    color: #757575;
    margin-bottom: 3%;
  }
  .inputs select:focus { 
    border: 1px solid var(--ciano);

  }
  .inputs .icon {
    position: absolute;
    right: 5px; 
    top: 50%;
    transform: translateY(-50%);
  }
  