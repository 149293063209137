.cardServicoCarroselHome{
    width: 26vw;
    height: 70vh;
    border: 1px solid #b5b5b5;
    border-radius: 0.8rem;
    box-shadow: rgba(130, 130, 130, 0.717) 0px 0px 10px;
}
 .cardServicoCarroselHome .cardServicoCarroselHomeImg{
    width: 100%;
    height: 55%;
    background-color: #00BABF;
    border-radius: 0.8rem 0.8rem 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.cardServicoCarroselHome .cardServicoCarroselHomeImg img {
    width: 100%;
    height: 100%; /* Alteração para ocupar toda a altura */
    object-fit: cover; /* Para garantir que a imagem cubra todo o espaço */
    border-radius: 0.8rem 0.8rem 0 0; /* Mantém o mesmo raio de borda do contêiner */
}
.cardServicoCarroselHome .cardServicoCarroselHomeTitulo, .cardServicoCarroselHome .cardServicoCarroselHomeOrcamento{
    color: var(--primaryTextColor);
    margin: 0.6rem 1vw;
    cursor: pointer;
}
.cardServicoCarroselHome .cardServicoCarroselHomeDescricao{
    min-height: 55px;
}
.cardServicoCarroselHome .cardServicoCarroselHomeDescricao, .cardServicoCarroselHome .cardServicoCarroselHomeAvaliacao{
    margin: 0.6rem 1vw;
}