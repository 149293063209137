.content {
  width: 100%;
  height: 100vh;
  overflow: hidden;

}


.content img {
    height: auto;
    max-width: 100%;
    object-fit: cover;
}
.contentMain{
    cursor: auto;
}