.sidebar {
  min-width: 230px;
    width: 230px;
    max-width: 230px;
    /* background-color: #bc8b8b; */
    color: #5D67DE;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    transition: width 0.3s ease;
    border-right: solid 1px #7ACFEC;
  }
  
  .sidebar.closed {
    min-width: 10vw;
    width: 10vw;
  }
  
  .logo {
    width: 30%;
    cursor: pointer;
  }
  .sidebar.closed .logo{
    width: 50%;
  }
  .logo img {
    width: 100%;
  }
  
  nav ul {
    list-style: none;
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
  nav ul .linkBtn{
    list-style: none;
    text-decoration: none;
  }
  nav ul li {
    margin: 5rem 0;
    /* margin-bottom: 10rem; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  nav ul li a {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 18px;
    margin-left: 10px;
  }
  nav ul li.active{
    background-color: #5D67DE;
    border-radius: 10px;
    color: #fff;
    padding: 0.5rem 1rem;
  }
  nav ul li.active a {
    font-weight: bold;
    color: #fff;
  }
  
  .logout button {
    background-color: #e97272;
    border: none;
    padding: 10px 35px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 150px;
  }
  
  .logout button span {
    margin-left: 10px;
  }
  
  .logoutSmall button, .logoutSmall button span{
    background-color: transparent;
    color: #5D67DE;
    border: none;
  }


  .sidebar.closed #dashIcon{
    margin-left: 0rem;
  }

  .logout button .iconeLogout{
    margin-left: -1.2rem;
  }

  nav ul .linkBtn li.active .corIcone{
    color: #fff;
  }

  .corIcone{
    color: #5D67DE;
  }	