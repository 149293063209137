
#perfilPrincipal #areaTopo{
    background-color: #595959;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#areaBotoes{
    /* background-color: #595959; */
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 88%;
    padding: 2%;
    padding-left: 6%;
    /* margin: auto; */
    margin-bottom: 5vh;
}
#areaBotoes .linkBtn{
    background-color: #ffffff;
    padding: 1% 8%;
    color: rgb(42, 42, 42);
    border: solid 1px #7ACFEC;
    border-radius: 0.3rem;
    cursor: pointer;
}
#areaBotoes .selected{
    background-color: #2971DA;
    box-shadow: 3px 3px 1px #191919;
    border: none!important;
}
#areaBotoes .selected button{
    color: #ffffff!important;
    cursor: pointer;
}
#areaBotoes .linkBtn button{
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: rgb(42, 42, 42);
}

.hidden-input {
    display: none;
}

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    position: fixed; /* ou absolute */
    backdrop-filter: blur(5px);
}

.modalBackground .modalContent {
    width: 35%;
    max-width: 50vw;
    height: 50vh;
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000; 
    padding-bottom: 10vh;
    box-shadow: #191919 6px 6px 1px;
}
.modalBackground .modalContent h3{
    text-align: center;
    margin-bottom: 2%;
    font-weight: 600;
}
.modalBackground .modalContent form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 95%;
    margin: auto
}
.modalBackground .modalContent form .modalCategoria{
    width: 100%;
    height: 20%;
}
.modalBackground .modalContent form .modalTituloServico {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5%;
}
.modalBackground .modalContent form .modalTituloServico .modalTituloInput{
    height: 5vh;
    width: 100%;
    outline: none;
    border-radius: 0.3rem;
    border: #757575 1px solid;

}
.modalBackground .modalContent form .modalTituloServico .modalTituloInput:focus{
    border: #7ACFEC 1px solid;
    
}
.modalBackground .modalContent form .modalDescricao{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.modalBackground .modalContent form .modalDescricao textarea{
    border: none;
    border-radius: 0.8rem;
    padding: 1% 2%;
    width: 100%;
    height: 10vh;
    border: #757575 1px solid;
    outline: #7ACFEC;
    box-sizing: border-box;
    resize: none;
}
.modalBackground .modalContent form .modalDescricao textarea:focus{
    border: #7ACFEC 1px solid;

}

.modalBackground .modalContent #btnCriarServico{
    background-color: #2971DA;
    color: #ffffff;
    padding: 2% 5%;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    box-shadow: #191919 3px 3px 1px;
}
.modalBackground .modalContent #btnCriarServico:hover{
    background-color: #1E5F9D;
}

.modalImgServico {
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalImgServico img {
    width: 80%;
}

.iconeEditar {
    cursor: pointer;
    color: #007bff;
}

.iconeEditar:hover {
    opacity: 0.7;
}

.editarCor {
    margin-right: 10px;
}

