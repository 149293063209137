#container {
    width: 92%;
    margin: auto;
    height: auto;
    color: var(--primaryTextColor);
    /* border-bottom: solid 1px #c7c7c7; */
}
#container #areaEmpresaTopo {
    width: 100%;
    height: 11rem;
    display: flex;
    justify-content: space-between;
    margin-top: 3vh;
}
#container #areaEmpresaTopo #divLogo {
    width: 15rem;
    height: 14rem;
    margin-top: -5rem;
    border-radius: 50%;
    box-shadow: #535353 6px 6px 1px;
}
#container #areaEmpresaTopo #divLogo img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

}
#container #areaEmpresaTopo #areaDesc {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    margin-left: 7%;
}
#container #areaEmpresaTopo #areaDesc #nomeEmpresa {
    font-size: 1.2rem;
    font-weight: 600;
    color: rgb(39, 39, 39);
}
#container #areaEmpresaTopo #areaDesc #contratos {
    width: 40%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #7ACFEC;
    box-shadow: 2px 1px 5px grey;
}
#container #areaEmpresaTopo #endereco {
    width: 30%;
    display: flex;
}
#container #areaEmpresaTopo #endereco p {
    margin-left: 2%;
}
#container #areaBaixo {
    height: 85vh;
    display: flex;
    margin-top: 7vh;
    border-bottom: solid 1px #c7c7c7;
    margin-bottom: 5vh;
}
#container #areaBaixo #descricao {
    width: 65%;
}
#container #areaBaixo #descricao #btnChat {
    width: 40%;
    height: 16%;
    box-shadow: 4px 4px 1px black;
    background-color: rgb(107, 107, 107);
    cursor: pointer;
    margin-top: -3vh;
}
#container #areaBaixo #descricao #btnChat button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-size: 1.5rem;
    color: #fff;
}
#container #areaBaixo #descricao{
    margin-top: 10vh;
    width: 100%;
    height: 70%;
}
#container #areaBaixo #descricao #descricaoEmpresa p {
    line-height: 2.6rem;
}
#container #areaBaixo #mapa {
    width: 35%;
    height: 70vh;
}

#nomeEmpresa {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 0px 5px;
    flex-direction: row;
}

#descricaoEmpresa {
    width: 80%;
    height: 70%;
    margin-top: 10vh;
    word-break: normal;
}

#descricaoEmpresa textarea, #descricaoEmpresa:focus {
    width: 80%;
    height: 100%;
    resize: none;
    outline: none;
}

.breveDesc {
    width: 70%;
    height: 50%;
    display: flex;
    align-items: start;
    flex-direction: row;
    gap: 0px 5px;
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.breveDesc textarea {
    width: 100%;
    height: 80%;
    word-break: break-all;
    white-space: normal;
    resize: none;
}

.breveDesc textarea:focus {
    outline: none;
}

.textAreaBrevDesc, .textAreaDesc, .inputNomeEmpresa {
    transition: all 0.3s ease;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.textAreaBrevDesc, .textAreaDesc {
    padding: 15px;
}

.inputNomeEmpresa {
    padding: 10px;
}

.textAreaBrevDesc:focus, .textAreaDesc:focus, .inputNomeEmpresa:focus {
    border-color: #5e84e2;
    background-color: #fff; 
    box-shadow: 0 0 8px rgba(94, 132, 226, 0.4);
}

.iconeEditar, .iconeSalvar {
    cursor: pointer;
    color: #007bff;
}

.iconeEditar:hover, .iconeSalvar {
    opacity: 0.7;
}

.inputNomeEmpresa {
    width: 100%;
    height: 35px;
}

.inputNomeEmpresa:focus {
    outline: none;
}

.areaAvaliacao {
    width: 100%;
    height: 70px;
    display: flex;
    /* background-color: red; */
}

.rating:not(:checked) > input {
    position: absolute;
    appearance: none;
}

.rating:not(:checked) > label {
    float: right;
    cursor: pointer;
    font-size: 40px;
    color: #666;
}

.rating:not(:checked) > label::before {
    content: '☆';
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~
label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~
label,
.rating > label:hover ~ input:checked ~
label {
    color: #e58e09;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label
{
    color: #ff9e0b;
}

.rating > input:checked ~ label {
    color: #ffa723;
}

.areaComentario {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9; /* Fundo claro */
    border: 1px solid #e0e0e0; /* Borda sutil */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.areaComentario h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.textareaComentario {
    width: 100%;
    height: 120px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
    resize: none;
    background-color: #fff;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease;
}

.textareaComentario:focus {
    border-color: #007BFF;
    outline: none;
}

.botaoEnviarComentario {
    align-self: flex-end;
    padding: 12px 20px;
    background-color: #2971DA;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 123, 255, 0.2);
}

.botaoEnviarComentario:hover {
    background-color: #0056b3;
}
