.container{
    position: relative;
    height: 100vh;
}
.side-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
    object-fit: cover;
}
.img2-logo{
    position: relative;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img2-logo img{
    height: 70%;
}

.img2-logo a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}