@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
:root{
  --primaryTextColor: #343434;
  --ciano: #00BABF;
  --laranja: #FF964D;
  --cinza: #606D80;
}

/* Estilo para a barra de rolagem */
::-webkit-scrollbar {
  width: 0.5rem
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background: #c7c7c7; 
}

::-webkit-scrollbar-corner {
  background: #f1f1f1; 
}
.carousel.carousel-slider .control-arrow {
  opacity: 1;
  background-color: #d9d9d99e !important;
  margin: 0%;
  z-index: 1;
  border-radius: 0.4rem;
}