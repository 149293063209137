
html {
  height: 100vh;
}

#containerDash {
  display: flex;
}



p {
  color: #888;
}

.container {
    width: 70%;
    max-height: 100vh;
  }
  #containerDash {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    color: var(--primaryTextColor);
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
  }
  
  .dashboard {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #3741b5;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.dash {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    /* background-color: red; */
}

.dash-group {
    width: 90%;
    height: 100%;
    border-radius: 14px;
    border: 1px solid #7ACFEC;
}

.kpis-group {
    width: 100%;
    height: auto;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: space-evenly;
}

.kpi {
    width: 19%;
    height: 100%;
    border: 1px solid #7ACFEC;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#containerDash .title {
    height: 20vh;
    width: 100%;
    display: flex;
    align-items: center;
    /* background-color: yellow; */
}

#containerDash .title h2 {
  margin-left: 5%;
  font-size: 2.5rem;
  font-weight: 500;
 
}

.container2 {
    width: 30%;
    border: 1px solid #7ACFEC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: blue; */
}

.logo-perfil {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.profile {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    /* background-color: red; */
    border: 1px solid #7ACFEC;
}
.profile img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.meu-perfil {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avaliacao {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
}

.avaliacao-cliente {
    width: 80%;
    height: 100%;
    border-radius: 4%;
    border: 1px solid #7ACFEC;
}

.top {
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: green; */
    border: 1px solid #7ACFEC;
    border-radius: 4%;
    cursor: pointer;
}

.top a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #292929;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top:hover {
    background-color: #5d67de;
}

.top-servicos {
    width: 80%;
    height: 100%;
    border-radius: 4%;
    border: 1px solid #7ACFEC;
}

.linkPerfil{
  text-decoration: none;
  color: #292929;
}

.grafico-acessos {
  margin-top: 40px;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.grafico-titulo {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.grafico-container {
  height: 200px; /* Ajuste conforme necessário */
}
