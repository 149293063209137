.divImgCarrossel {
    width: 100%;
    height: 30vh;
}

.imgCarrossel {
    width: 100%;
    height: 100%;
    object-fit:cover;
}

.customDots{
    background-color: rgb(55, 87, 116);
    margin-top: -10vh!important;
    height: fit-content;
    width: 100%;
}