#container {
    width: 88%;
    padding: 2%;
    margin: auto;
    height: auto;
    color: var(--primaryTextColor);
    border-bottom: solid 1px #c7c7c7;
    box-shadow: #a1a1a196 0px 0px 10px;
 
}

#container .areaTitulo {
    width: 100%;
    height: auto;
}

#container .areaTitulo h1 {
    font-size: 2em;
    font-weight: 500;
    color: var(--primaryTextColor);
    margin-bottom: 0.5rem;
}

#container p {
    margin-bottom: 0.5rem;
    line-height: 2.2rem;
}

#container .areaImagem {
    width: 100%;
    height: 70vh;
}

#container .areaImagem img {
    width: 100%;
    height: 100%;
    
}

#areaPublicar{
    margin: 0 auto 10vh auto;
}
#areaPublicar .publication-input {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    /* Arredondamento dos cantos */
    padding: 10px 0 ;
    height: 20vh;
    width: 100%;
}

#areaPublicar .publication-input .logoPost {
    width: 3rem;
    height: 3rem;
    margin-left: 3%;
    margin-right: -6%;
    border-radius: 50%;
}

#areaPublicar .publication-input .logoPost img {
    width: 100%;
    height: 100%;
}

#areaPublicar .publication-input .textarea-container {
    position: relative;
    height: auto;
    margin: 0 auto ;
    width: 80%;
}


#areaPublicar .publication-input .textarea-container textarea {
    border: none;
    background-color: #e0e0e0;
    border-radius: 0.8rem;
    padding: 1% 2%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    resize: none;
    padding-top: 5vh;
}
#areaPublicar .publication-input .textarea-container .titleInput{
    background-color: transparent;
    border: none;
    position: absolute;
    left: 1.1rem;
    top: 0.5rem;
    border-bottom: solid 1px #9e9e9e;
    outline: none;
}
#areaPublicar .publication-input .textarea-container .actions {
    position: absolute;
    bottom: 10px;
    left: 88%;
    width: 18%;
}
#areaPublicar .publication-input .textarea-container .actions button{
    background-color: transparent;
    border: none;
    height: 5vh;
    width: 3vw;
    cursor: pointer;
    margin-right: 3%;
}
#areaPublicar .publication-input .textarea-container .actions button FontAwesomeIcon{
    width: 100%;
    height: 100%;
}

#areaPublicar .publication-input .textarea-container .actions .send-button{
    background-color: #3A3A3A;
    border-radius: 0.5rem;
    color: #fff;
}

#container #publicacoes{
    width: 84%;
    padding: 2% 3%;
    height: auto;
    margin: auto;
    margin-bottom: 5vh;
}
#container #publicacoes .post{
    height: auto;
    padding-bottom: 5vh;
    margin-bottom: 5vh;
    border-bottom: solid 2px #a9a9a9;
}
.fileName {
   position: absolute;
    top: 3rem;
    right: 3rem;
    font-size: 14px;
    color: #555;
}

.arquivoButton{
    margin-right: 1vw;
    cursor: pointer;
}

.load-more{
    width: 100%;
    display: flex;
    justify-content: center;
}
.load-more button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}