.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 94vw;
  padding: 0 2vw 0 4vw;
  margin: auto;
  /* background-color: yellow; */
  height: 16.5vh;
  box-shadow: 0px 4px 10px 0px #4e4e4e47;
}

.navBar .logo {
  width: 7vw;
  height: 11vh;
  /* background-color: rgb(137, 82, 82); */
}

.navBar .logo img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.navBar #menuLista {
  /* background-color: green; */
  width: 50%;
  height: 40%;
  /* background-color: #fff; */
  border-radius: 20px;
  display: flex;
  align-items: center;
}
html {
  scroll-behavior: smooth;
}
.navBar #menuLista ul {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  list-style: none;
  width: 100%;
  /* margin: auto; */
}
.navBar #menuLista ul li a{
  text-decoration: none;
  color: #3a3a3a;
}
.navBar #menuLista ul li {
  cursor: pointer;
  
  transition: background-size 0.3s ease; 
  background-image: linear-gradient(to right, var(--ciano), var(--laranja));
  background-size: 0% 2px; 
  background-position: left bottom; 
  background-repeat: no-repeat;
}

.navBar #menuLista ul li:hover, .navBar #btnsTopo #btnLogin:hover, .navBar #btnsTopo #btnCadastro:hover {
  background-size: 100% 2px; /* Largura total da borda ao passar o mouse */

}

.navBar #btnsTopo {
  /* background-color: aqua; */
  width: 18%;
  height: 40%;
  display: flex;
  justify-content: space-between;
  margin-right: 5vw;
  

}

.navBar #btnsTopo #btnLogin {
  width: 50%;
  cursor: pointer;
  font-weight: 500;
  background-color: transparent;
  border: none;

  transition: background-size 0.3s ease; 
  background-image: linear-gradient(to right, var(--ciano), var(--laranja));
  background-size: 0% 2px; 
  background-position: left bottom;
  background-repeat: no-repeat; 
}
.linhaMeio{
  width: 0.1rem;
  height: auto;
  background-color: #252525;
}
.navBar #btnsTopo #btnCadastro {
  width: 50%;
  cursor: pointer;
  font-weight: 500;
  background-color: transparent;
  border: none;

  transition: background-size 0.3s ease; 
  background-image: linear-gradient(to right, var(--ciano), var(--laranja));
  background-size: 0% 2px; 
  background-position: left bottom;
  background-repeat: no-repeat; 
}

.btnLink{
  text-decoration: none;
  color: var(--primaryTextColor);
  font-size: 1rem;
}

.navBarServicos {
  height: 11vh;
  width: 94vw;
  padding: 0 3vw 0 3vw;
}

.navBarServicos .logo {
  width: 5vw;
  height: 8vh;
}

.navBarPerfil{
  height: 11vh;
  display: flex;
}
.navBarPerfil .logo {
  width: 5vw;
  height: 8vh;
  /* background-color: rgb(137, 82, 82); */
}
.navBarPerfil .imgPerfil, .navBarServicos .imgPerfil {
  width: 4vw;
  height: 8vh;
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgb(112, 112, 112);
}

.navBarPerfil .imgPerfil img, .navBarServicos .imgPerfil img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
}

.navBarPerfil #menuLista{
  width: 20vw;
}

.containerPerfil{
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: space-between;
}
.containerPerfil .btnLogout p{
  color: rgb(194, 60, 60);
  cursor: pointer;
}
@media screen and (max-width: 800px) {
 
  
  .navBar #menuLista ul {
    display: none;
  }
  .navBar  #btnsTopo{
    width: 35vw;
  }
  
}